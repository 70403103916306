import Layout from "./common/layout"
import Engagement from "./service/Engagement/Engagement"
import Head from "./common/Head"
import { useFormatter } from "helpers/i18n"

const ServiceEngagement = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "E-commerce shopper engagement with automation"
            : "Chăm sóc khách hàng, tối ưu tự động hoá"
        }
        description={
          currentLocale === "en"
            ? "Customer satisfaction is key to steady e-commerce growth for businesses, therefore, OnPoint provides excellent customer service, making the shopping experience pleasant and easy."
            : "OnPoint không ngừng nâng cao chất lượng dịch vụ khách hàng, cải thiện sự hài lòng của người mua, giúp họ có trải nghiệm tốt nhất và tiếp tục quay lại để mua sắm."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/service-3-meta.png"
        }
      />
      <Layout>
        <Engagement />
      </Layout>
    </>
  )
}

export default ServiceEngagement
