import ContainerMain from "src/pages/common/ContainerMain"
import logo from "images/FooterPage/logo-footer.svg"
import icon1 from "images/FooterPage/icon-1.png"
import icon2 from "images/FooterPage/icon-2.png"
import "./FooterPage.scss"
import { useFormatter } from "../../helpers/i18n"

export default function FooterPage() {
  const { __, Link } = useFormatter()
  return (
    <>
      <div className="jcgnCeVorZ">
        <ContainerMain>
          <div className="SbnPNCEOwC">
            <div className="NarqnYBhzg">
              <div className="qmAiBSoPaS">
                <img alt="logo" className="FxcyepeBKq" src={logo} />
              </div>
              <div className="arObSJBKCc">
                {__({
                  defaultMessage:
                    "27B Nguyen Dinh Chieu St., Da Kao Ward, District 1, HCMC",
                })}
              </div>

              <div className="arObSJBKCc">
                <img alt="" className="GPnrnrSPxQ" src={icon1} />
                <a href="tel:+842873056686">(+84) 028 73056686</a>
              </div>
              <div className="arObSJBKCc">
                <img alt="" className="GPnrnrSPxQ" src={icon2} />{" "}
                <div className="list-email">
                  <div className="email">
                    <a href="mailto:contact@onpoint.vn">contact@onpoint.vn</a>
                  </div>
                  <div className="email">
                    <a href="mailto:sales@onpoint.vn">sales@onpoint.vn</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="NarqnYBhzg">
              <div className="arneZmwYLE">
                {__({ defaultMessage: "Our Products" })}
              </div>
              <div className="NaAGewMQjG">
                <Link to="/product-integrated">
                  {__({
                    defaultMessage:
                      "OctoPOS - Online-Offline Integrated Order Management",
                  })}{" "}
                </Link>
              </div>

              <div className="NaAGewMQjG">
                <Link to="/product-performance">
                  {__({ defaultMessage: "Performance Dashboard" })}{" "}
                </Link>
              </div>
            </div>
            <div className="NarqnYBhzg">
              <div className="arneZmwYLE">
                {__({ defaultMessage: "Our Services" })}
              </div>
              <div className="NaAGewMQjG">
                <Link to="/service-marketing">
                  {__({ defaultMessage: "E-marketing Management" })}{" "}
                </Link>
              </div>
              <div className="NaAGewMQjG">
                <Link to="/service-store">
                  {__({ defaultMessage: "E-store Management" })}{" "}
                </Link>
              </div>
              <div className="NaAGewMQjG">
                <Link to="/service-engagement">
                  {__({ defaultMessage: "Shopper Engagement" })}{" "}
                </Link>
              </div>
              <div className="NaAGewMQjG">
                <Link to="/service-logistics">
                  {__({
                    defaultMessage: "Omni-channel Logistics & Fulfilment",
                  })}
                </Link>
              </div>
            </div>
            <div className="NarqnYBhzg">
              <div className="arneZmwYLE">
                {__({ defaultMessage: "Useful Links" })}
              </div>
              <div className="NaAGewMQjG">
                <Link to="/privacy-policy">
                  {__({ defaultMessage: "Privacy Policy" })}
                </Link>
              </div>
              <div className="NaAGewMQjG">
                <Link to="/terms-of-service">
                  {__({ defaultMessage: "Terms of Service" })}
                </Link>
              </div>
              <div className="FFHyFJuvUO">
                <div className="aqmosvhaDe">
                  <a
                    className="StNtFzDeox"
                    target="_blank"
                    href="https://www.facebook.com/OnpointVietnam"
                  >
                    <div className="circle">
                      <span className="icon fa-brands fa-facebook-f"></span>
                    </div>
                  </a>
                </div>
                <div className="aqmosvhaDe">
                  <a
                    className="StNtFzDeox"
                    target="_blank"
                    href="https://www.linkedin.com/company/onpointvn/"
                  >
                    <div className="circle">
                      <span className="icon fa-brands fa-linkedin-in"></span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
      <div className="zpVHgooayN">OnPoint © 2022. All Rights Reserved.</div>
    </>
  )
}
