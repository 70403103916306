import "./SubTitle.scss"

export default function SubTitle({ children, nColClass }) {
  return (
    <div className={`section__sub-title`}>
      {typeof children === "string" || children instanceof String ? (
        <div
          className={`section__sub-title__children ${nColClass}`}
          dangerouslySetInnerHTML={{
            __html: children,
          }}
        />
      ) : (
        <div className={`section__sub-title__children ${nColClass}`}>
          {children}
        </div>
      )}
    </div>
  )
}
