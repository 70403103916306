import "./LiveChat.scss"
import SectionTitle from "pages/common/SectionTitle"
import ContainerMain from "pages/common/ContainerMain"
import SubTitle from "pages/common/SubTitle"
import Content from "pages/common/Content"

import image1 from "images/Service/Engagement/service-3-chat.png"
import icon1 from "images/Service/Engagement/icon-1.svg"
import { useFormatter } from "helpers/i18n"

export default function LiveChat() {
  const { __ } = useFormatter()
  return (
    <>
      <div className="kcxjysztfe">
        <ContainerMain>
          <SectionTitle
            title={__({
              defaultMessage: "Live chat",
            })}
          />
          <SubTitle
            children={__({
              defaultMessage:
                "With well-trained agents who deeply understand about brands, products and promotions, we deliver a 100% response rate and high volume chat management.",
            })}
          />
          <Content>
            <div className="wwzdjczuoz">
              <div className="fgjkakcees">
                <div className="fgzmmwwuip">
                  <img src={image1} className="ogqhdosiss" />
                </div>
                <div className="cjzlmcwlag">
                  <div className="azxnxwmvlf">
                    <div className="azxnxwmvlf">
                      {__({
                        defaultMessage: "Chatbot",
                      })}
                    </div>
                  </div>
                  <div className="qfqzxdzcfb">
                    {__({
                      defaultMessage:
                        " We provide a much better Customer Experience in CS/CX activities at a lower cost via chatbot.",
                    })}
                  </div>
                  <div className="rcmhgopyqm">
                    {__({
                      defaultMessage: "Various Types for Your Business Needs",
                    })}
                  </div>
                  <div className="kcrbuvcjtp">
                    <div className="xcdodbozxl">
                      <div className="oishwwdkzf">
                        <span>{`${__({
                          defaultMessage: " Sales chatbot",
                        })}`}</span>
                        {__({
                          defaultMessage:
                            ": Generating Sales from current customers via Broadcasting messages",
                        })}
                      </div>
                      <img src={icon1} alt="" className="thrvhxrtvf" />
                    </div>
                    <div className="xcdodbozxl">
                      <div className="oishwwdkzf">
                        <span>
                          {__({
                            defaultMessage: "Re-marketing chatbot: ",
                          })}
                        </span>{" "}
                        {__({
                          defaultMessage:
                            "Customizing marketing effort to different customer segments based on their historical data",
                        })}
                      </div>
                      <img src={icon1} alt="" className="thrvhxrtvf" />
                    </div>
                    <div className="xcdodbozxl">
                      <div className="oishwwdkzf">
                        <span>
                          {`${__({
                            defaultMessage: "Engagement chatbot:",
                          })}`}
                        </span>{" "}
                        {__({
                          defaultMessage:
                            "Enhancing Brand love & digital experience",
                        })}
                      </div>
                      <img src={icon1} alt="" className="thrvhxrtvf" />
                    </div>
                    <div className="xcdodbozxl">
                      <div className="oishwwdkzf">
                        <span>
                          {__({
                            defaultMessage: "Customer service chatbot:",
                          })}
                        </span>{" "}
                        {__({
                          defaultMessage:
                            "24/7 digital experience, driving down cost by auto-reply repetitive questions",
                        })}
                      </div>
                      <img src={icon1} alt="" className="thrvhxrtvf" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </ContainerMain>
      </div>
    </>
  )
}
