import BannerHeader from "pages/common/BannerHeader"
import LiveChat from "pages/service/Common/LiveChat"
import BoxBackground from "pages/service/Common/BoxBackground"
import GetStart from "pages/common/GetStart"
import { useFormatter } from "../../../helpers/i18n"
import bannerMobile from "images/Service/Engagement/service-3-banner-mobile.png"
import { StaticImage } from "gatsby-plugin-image"
import CRM from "./components/CRM"

export default function Engagement() {
  const banner = "../../../images/Service/Engagement/service-3-banner.png"

  const { __ } = useFormatter()

  return (
    <>
      <BannerHeader
        breadcrumb={[
          {
            label: `${__({ defaultMessage: "Home/" })}`,
            url: "/",
          },
          {
            label: `${__({ defaultMessage: "Services/" })}`,
          },
          {
            label: `${__({
              defaultMessage: "Shopper Engagement",
            })}`,
          },
        ]}
        banner={
          <StaticImage
            layout="fullWidth"
            src={banner}
            alt="Shopper Engagement Banner"
            decoding="async"
            fetchpriority="high"
            quality={100}
          />
        }
        mobileBanner={
          <img src={bannerMobile} alt="" style={{ width: "100%" }} />
        }
        logo={""}
        listTitle={[`${__({ defaultMessage: "Shopper Engagement" })}`]}
        description={`${__({
          defaultMessage:
            "Customer satisfaction is key to steady e-commerce growth for businesses. Therefore, we provide excellent customer service, making the shopping experience pleasant and easy.",
        })}`}
      />
      {[
        <LiveChat />,
        <BoxBackground
          title={`${__({ defaultMessage: "Rating & Review Management" })}`}
          description={`
        ${__({
          defaultMessage:
            "We proactively manage rating and review sections to build customer loyalty and enhance brand equity. We turn shoppers into brand advocates.",
        })}
        `}
        />,
        <CRM />,
      ].map((component, key) => (
        <section key={key} className={key > 0 ? "section" : null}>
          <div className="reveal">{component}</div>
        </section>
      ))}
      <GetStart />
    </>
  )
}
