import SectionTitle from "pages/common/SectionTitle"
import ContainerMain from "pages/common/ContainerMain"
import SubTitle from "pages/common/SubTitle"
import "./BoxBackground.scss"

export default function BoxBackground({ title = "", description = "" }) {
  return (
    <>
      <div className="pemzuphfzc">
        <ContainerMain>
          <SectionTitle title={title} />
          <SubTitle children={description} />
        </ContainerMain>
      </div>
    </>
  )
}
