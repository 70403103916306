import { useFormatter } from "helpers/i18n"
import SectionTitle from "pages/common/SectionTitle"
import ContainerMain from "pages/common/ContainerMain"
import SubTitle from "pages/common/SubTitle"
import Content from "pages/common/Content"
import imageBanner1 from "images/Service/Engagement/service-3-crm.png"
import "./CRM.scss"

export default function CRM() {
  const { __ } = useFormatter()
  const contents = [
    {
      id: 1,
      title: `${__({
        defaultMessage: "Answer FAQs in a Blink!",
      })}`,

      content: `${__({
        defaultMessage:
          "Giving out greetings, FAQs and prepared answers to these FAQs",
      })}`,
    },
    {
      id: 2,
      title: `${__({
        defaultMessage: "Re-route to CS When Needed",
      })}`,
      content: `${__({
        defaultMessage:
          "Retaining human support can ensure best customer experience when needed.",
      })}`,
    },
    {
      id: 3,
      title: `${__({
        defaultMessage: "Follow Shopper Journey",
      })}`,
      content: `${__({
        defaultMessage:
          "Define shoppers footprints by codes. For example: first purchase, re-purchased, chatted, lapsed 3 months...",
      })}`,
    },
    {
      id: 4,
      title: `${__({
        defaultMessage: "Marketing Automation",
      })}
      `,
      content: `${__({
        defaultMessage:
          "For example, shoppers who has not come back for 6 months will automatically be sent a promotion offer via message, SMS or Zalo.",
      })}`,
    },
  ]

  return (
    <div className="service-engagement-crm">
      <ContainerMain>
        <SectionTitle title={`${__({ defaultMessage: " CRM/CEM" })}`} />
        <SubTitle
          children={`
          ${__({
            defaultMessage:
              "We continuously develop our CRM system through data analytics, chatbot, and intent-base chat management strategy to review and take actions accordingly.",
          })}
          `}
        />
      </ContainerMain>
      <Content>
        <ContainerMain alignType="right">
          <div className="service-engagement-crm__content">
            <div className="service-engagement-crm__content__image_wrapper">
              <div className="service-engagement-crm__content__image">
                <img src={imageBanner1} alt="" />
              </div>
            </div>
            <div className="service-engagement-crm__content_wrapper">
              <div className="service-engagement-crm__content__text">
                {contents.map((item, index) => {
                  const key = (Math.floor(index / 2) % 2) + 1
                  return (
                    <CRMContentItem
                      {...item}
                      className={`no-${key}`}
                      key={index}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </ContainerMain>
      </Content>
    </div>
  )
}

function CRMContentItem({ title, content, className }) {
  return (
    <div className={`service-engagement-crm__content_item ${className}`}>
      <div className="service-engagement-crm__content_item_title">{title}</div>
      <div className="service-engagement-crm__content_item_content">
        {content}
      </div>
    </div>
  )
}
// ;<WhatWeDoV3
//   paddingTitleTop={115}
//   titleHeader={`${__({ defaultMessage: " CRM/CEM" })}`}
//   descriptionHeader={`
//           ${__({
//             defaultMessage:
//               "We continuously develop our CRM system through data analytics, chatbot, and intent-base chat management strategy to review and take actions accordingly.",
//           })}
//           `}
//   contents={contents}
//   image={imageBanner1}
//   // title="Our Integrated Automatic-CRM System"
// />
